.App {
  padding: 10px;
}

.App-toasts {
  position: fixed;
  padding: 10px;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--bg-color);
}

.App-toast {
  animation: fadeOut 5s;
  animation-fill-mode: forwards;
}

.App-error {
  color: maroon;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (min-width: 768px) {
  .App-toasts {
    left: auto;
  }
  .App-toast {
    text-align: right;
  }
}
