.Header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.Header-left {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.Header-right {
  -webkit-flex: 1 1;
          flex: 1 1;
  text-align: right;
}

.Header-title {
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
}

.Header-theme-btn {
  background-color: var(--fg-color);
  display: inline-block;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  outline: none;
}

@media (min-width: 768px) {
  .Header-theme-btn {
    width: 30px;
    height: 30px;
  }
}

.Footer {
  padding: 50px 0;
  text-align: center;
}

@media (min-width: 768px) {
}

.Search {
  position: relative;
  margin: -36px auto 20px;
  width: 100%;
  max-width: 768px;
  padding-top: 0;
  transition: padding 0.1s;
}
.Search:after {
  content: '';
  display: block;
  position: relative;
  margin: 0 auto;
  max-width: 768px;
  width: 0;
  height: 1px;
  background-color: var(--fg-color);
  -webkit-animation: shrink 0.1s;
          animation: shrink 0.1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.Search.active {
  padding-top: 30px;
}
.Search.active:after {
  -webkit-animation: expand 0.1s;
          animation: expand 0.1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes expand {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@keyframes expand {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@-webkit-keyframes shrink {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}
@keyframes shrink {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}

.Search-input {
  position: relative;
  display: block;
  z-index: 1;
  margin: 0;
  padding: 10px 5px;
  width: 100%;
  font-size: 1rem;
  background: transparent;
  color: var(--fg-color);
  border: 0;
  border-bottom: 1px solid transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  outline: none;
  transition: border-color 0.1s;
  text-align: center;
}
.Search-input::-webkit-input-placeholder {
  color: var(--fg-color);
  opacity: 0.75;
}
.Search-input::-ms-input-placeholder {
  color: var(--fg-color);
  opacity: 0.75;
}
.Search-input::placeholder {
  color: var(--fg-color);
  opacity: 0.75;
}
.Search-input:-ms-input-placeholder {
  color: var(--fg-color);
  opacity: 0.75;
}
.Search-input::-ms-input-placeholder {
  color: var(--fg-color);
  opacity: 0.75;
}

@media (min-width: 768px) {
  .Search.active {
    padding-top: 0;
  }
}

.Toast {
}

@media (min-width: 768px) {
}

.Cards {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 20px;
}

.Card {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
}

.Card-title {
  position: relative;
  margin: 0;
  font-weight: bold;
  font-size: 1.375rem;
  text-align: center;
}
.Card-title:after {
  /* content: ''; */
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0px;
  height: 3px;
  background: #000;
}

.Card-content {
  padding: 0;
}

ul {
  list-style: none;
  margin: 0;
}

@media (min-width: 768px) {
}

.StatItem {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 8px;
}

.StatItem-label {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: inline;
  line-height: 16px;
  font-family: 'Courier New', Courier, monospace;
  text-align: left;
}

.StatItem-value,
.StatItem-input {
  display: inline;
  font-weight: bold;
  letter-spacing: 0.5px;
  padding: 0;
  line-height: 16px;
}

.StatItem-value {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.StatItem-input {
  color: var(--fg-color);
  position: relative;
  margin-top: -3px;
  height: 18px;
  border: 0;
  font-size: 1rem;
  -webkit-appearance: none;
  outline: none;
  background: transparent;
}

.StatItem-copy-btn {
  opacity: 1;
  position: relative;
  top: 1px;
  margin-top: -3px;
  height: 20px;
  width: 20px;
  float: right;
  border: 0;
  padding: 0;
  text-align: center;
  background: transparent;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  outline: none;
  z-index: 3;
  overflow: hidden;
}
.StatItem-copy-btn .svg-icon {
  height: 100%;
  width: 100%;
}

.StatItem.copyable .StatItem-value {
  margin-top: -1px;
}
.StatItem.copyable .StatItem-value,
.StatItem.copyable .StatItem-input {
  cursor: pointer;
}
.StatItem.copyable:hover .StatItem-copy-btn {
  opacity: 1;
}

.StatItem-copy-btn:active:after {
  transition: none;
  height: 18px;
}

@media (min-width: 768px) {
  .StatItem {
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-bottom: 5px;
  }
  .StatItem-label {
    text-align: right;
    padding-right: 3px;
  }
  .StatItem-value {
    padding-left: 3px;
  }
  .StatItem-copy-btn {
    opacity: 0.75;
  }
}

.App {
  padding: 10px;
}

.App-toasts {
  position: fixed;
  padding: 10px;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--bg-color);
}

.App-toast {
  -webkit-animation: fadeOut 5s;
          animation: fadeOut 5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.App-error {
  color: maroon;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (min-width: 768px) {
  .App-toasts {
    left: auto;
  }
  .App-toast {
    text-align: right;
  }
}

:root {
  --bg-color: #f6f6f6;
  --fg-color: #1d1e22;
  --primary-color: dodgerblue;
}

.dark-theme {
  --bg-color: #1d1e22;
  --fg-color: #f6f6f6;
  --primary-color: deepskyblue;
}

* {
  box-sizing: border-box;
}

body {
  background-color: #f6f6f6;
  background-color: var(--bg-color);
  color: #1d1e22;
  color: var(--fg-color);
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: dodgerblue;
  color: var(--primary-color);
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

/* -----
SVG Icons - svgicons.sparkk.fr
----- */

.svg-icon {
  width: 2rem;
  height: 2rem;
}

.svg-icon path,
.svg-icon polygon,
.svg-icon rect {
  fill: var(--fg-color);
}

.svg-icon circle {
  stroke: var(--fg-color);
  stroke-width: 1;
}

