.Header {
  display: flex;
  flex-direction: row;
}

.Header-left {
  flex: 1;
}

.Header-right {
  flex: 1;
  text-align: right;
}

.Header-title {
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
}

.Header-theme-btn {
  background-color: var(--fg-color);
  display: inline-block;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 0;
  user-select: none;
  outline: none;
}

@media (min-width: 768px) {
  .Header-theme-btn {
    width: 30px;
    height: 30px;
  }
}
