:root {
  --bg-color: #f6f6f6;
  --fg-color: #1d1e22;
  --primary-color: dodgerblue;
}

.dark-theme {
  --bg-color: #1d1e22;
  --fg-color: #f6f6f6;
  --primary-color: deepskyblue;
}

* {
  box-sizing: border-box;
}

body {
  background-color: var(--bg-color);
  color: var(--fg-color);
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
