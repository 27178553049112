.Cards {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 20px;
}

.Card {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
}

.Card-title {
  position: relative;
  margin: 0;
  font-weight: bold;
  font-size: 1.375rem;
  text-align: center;
}
.Card-title:after {
  /* content: ''; */
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0px;
  height: 3px;
  background: #000;
}

.Card-content {
  padding: 0;
}

ul {
  list-style: none;
  margin: 0;
}

@media (min-width: 768px) {
}
