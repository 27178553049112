/* -----
SVG Icons - svgicons.sparkk.fr
----- */

.svg-icon {
  width: 2rem;
  height: 2rem;
}

.svg-icon path,
.svg-icon polygon,
.svg-icon rect {
  fill: var(--fg-color);
}

.svg-icon circle {
  stroke: var(--fg-color);
  stroke-width: 1;
}
