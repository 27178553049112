.StatItem {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.StatItem-label {
  flex: 1;
  display: inline;
  line-height: 16px;
  font-family: 'Courier New', Courier, monospace;
  text-align: left;
}

.StatItem-value,
.StatItem-input {
  display: inline;
  font-weight: bold;
  letter-spacing: 0.5px;
  padding: 0;
  line-height: 16px;
}

.StatItem-value {
  flex: 1;
}

.StatItem-input {
  color: var(--fg-color);
  position: relative;
  margin-top: -3px;
  height: 18px;
  border: 0;
  font-size: 1rem;
  -webkit-appearance: none;
  outline: none;
  background: transparent;
}

.StatItem-copy-btn {
  opacity: 1;
  position: relative;
  top: 1px;
  margin-top: -3px;
  height: 20px;
  width: 20px;
  float: right;
  border: 0;
  padding: 0;
  text-align: center;
  background: transparent;
  cursor: pointer;
  user-select: none;
  outline: none;
  z-index: 3;
  overflow: hidden;
}
.StatItem-copy-btn .svg-icon {
  height: 100%;
  width: 100%;
}

.StatItem.copyable .StatItem-value {
  margin-top: -1px;
}
.StatItem.copyable .StatItem-value,
.StatItem.copyable .StatItem-input {
  cursor: pointer;
}
.StatItem.copyable:hover .StatItem-copy-btn {
  opacity: 1;
}

.StatItem-copy-btn:active:after {
  -webkit-transition: none;
  transition: none;
  height: 18px;
}

@media (min-width: 768px) {
  .StatItem {
    flex-direction: row;
    margin-bottom: 5px;
  }
  .StatItem-label {
    text-align: right;
    padding-right: 3px;
  }
  .StatItem-value {
    padding-left: 3px;
  }
  .StatItem-copy-btn {
    opacity: 0.75;
  }
}
