.Search {
  position: relative;
  margin: -36px auto 20px;
  width: 100%;
  max-width: 768px;
  padding-top: 0;
  transition: padding 0.1s;
}
.Search:after {
  content: '';
  display: block;
  position: relative;
  margin: 0 auto;
  max-width: 768px;
  width: 0;
  height: 1px;
  background-color: var(--fg-color);
  animation: shrink 0.1s;
  animation-fill-mode: forwards;
}
.Search.active {
  padding-top: 30px;
}
.Search.active:after {
  animation: expand 0.1s;
  animation-fill-mode: forwards;
}
@keyframes expand {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@keyframes shrink {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}

.Search-input {
  position: relative;
  display: block;
  z-index: 1;
  margin: 0;
  padding: 10px 5px;
  width: 100%;
  font-size: 1rem;
  background: transparent;
  color: var(--fg-color);
  border: 0;
  border-bottom: 1px solid transparent;
  user-select: none;
  outline: none;
  transition: border-color 0.1s;
  text-align: center;
}
.Search-input::placeholder {
  color: var(--fg-color);
  opacity: 0.75;
}
.Search-input:-ms-input-placeholder {
  color: var(--fg-color);
  opacity: 0.75;
}
.Search-input::-ms-input-placeholder {
  color: var(--fg-color);
  opacity: 0.75;
}

@media (min-width: 768px) {
  .Search.active {
    padding-top: 0;
  }
}
